export default {

    paginas:[
        {
            tipotela:'telacheia',
            htmlContentEsquerda:{
                titulo:`FD Guest - Manutenção`,
                subtitulo:`(Utilizacao)`
            },
            htmlContentDireita:{
                conteudo:``
            }
        },
        {
            tipotela:'telacomimagem',
            htmlContentEsquerda:{
                img:`/Treinamentos/fdguest/manutencao/manutencao-chamados.jpg`,
                css:`width:100%!important;height:70%!important;`
            },
            htmlContentDireita:{
                conteudo:`<h1>FD Guest</h1>
                            <ul>
                                <li> - O FDGuest Manutenção é uma ferramenta que visa auxiliar na gestão de atendimentos de manutenção do hotel.</li>
                                <li> - Permite que Chamados especificos de uma area seja distribuido a um técnico especifico</li>
                                <li> - Gestão de chamados</li>
                                <li> - Relatórios de chamados</li>
                                <li> - O tecnico receberá os chamados através do Aplicativo para Android</li>
                            </ul>
                        `
            }
        },
        {
            tipotela:'telacomimagem',
            htmlContentEsquerda:{
                img:`/Treinamentos/fdguest/manutencao/app-tela-inicial.jpg`,
                css:`width:40%!important;height:80%!important;`
            },
            htmlContentDireita:{
                conteudo:`<h1>Aplicativo Tecnico FD Guest</h1>
                            <ul>
                                <li> - Aplicativo FD Guest para Android.</li>
                                <li> - Tecnico Recebe os Chamados</li>
                                <li> - Tecnico Realiza os chamados</li>
                            </ul>
                        `
            }
        },
        {
            tipotela:'telacomimagem',
            htmlContentEsquerda:{
                img:'/Treinamentos/fdguest/manutencao/web-login.jpg',
                css:'width:70%!important;height:60%!important;',
                // linkVideo:'https://esvideos.economysoftware.com.br/videostutoriais/espot/1-Espot-Login.mp4'
                // linkVideo:'/Treinamentos/fdguest/manutencao/web-login.jpg'
            },
            htmlContentDireita:{
                conteudo:`<h1>Tela de Login Administrativo</h1>
                <ul>
                    <li> - Tela de acesso Administrativo</li>
                    <li> - Acesso somente a colaboradores ativos</li>
                    <li> - Padrão de URL: https://<b>[nomedohotel]</b>.fdguest.hotel.economysoftware.com.br</li>
                </ul>
            `
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguestmanutencao/login.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Realizando Login</h1>
                <ul>
                    <li> - Padrão de URL: https://<b>[nomedohotel]</b>.fdguest.hotel.economysoftware.com.br</li>
                </ul>`
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguestmanutencao/cadastrousuarios.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Cadastro de Usuarios</h1>
                <ul>
                    <li> - Filtrar e Pesquisar</li>
                    <li> - Editar : Dados, Acessos, Acessos Por Setor, Escala de Trabalho</li>
                    <li> - Ativar/Desativar</li>
                    <li> - Edição</li>
                </ul>
            `
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguestmanutencao/uploadprodutos.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Produtos da Manutenção - Via Upload</h1>
                <ul>
                    <li> - Planilha .CSV no modelo especificado</li>
                </ul>`
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguestmanutencao/movimentacao.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Produtos da Manutenção - Movimentação</h1>
                <ul>
                    <li> - Editar manualmente a quantidade</li>
                    <li> - Editar manualmente o Status</li>
                    <li> - Editar manualmente o Icone de Exibição</li>
                    <li> - Visualizar Historico de Manutenção</li>
                </ul>`
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguestmanutencao/categoriasitens.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Cadastro de Categorias e Itens</h1>
                <ul>
                    <li> - Cadstro de Categorias</li>
                    <li> - Cadastro de Itens da Categoria</li>
                </ul>`
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguestmanutencao/vinculartecnicos.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Vincular Tecnicos as Categorias Especificas</h1>
                <ul>
                    <li> - Vincular Tecnicos as Categorias e Itens</li>
                    <li> - Definir escala de Trabalho</li>
                    <li> - Folgas</li>
                </ul>`
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguestmanutencao/localdeatendimento.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Locais de Atendimento</h1>
                <ul>
                    <li> - Cadastro de Locais de Atendimento para Direcionamento de Chamados</li>
                </ul>`
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguestmanutencao/equipamentos.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Equipamentos</h1>
                <ul>
                    <li> - Cadastro de Equipamentos para Direcionamento de Chamados</li>
                </ul>`
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguestmanutencao/fornecedores.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Cadastro de Fornecedores e Parceiros</h1>
                <ul>
                    <li> - Cadastro de Fornecedores para registro de Chamados externos</li>
                </ul>`
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguestmanutencao/chamadosemuh.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Chamados Sem UH</h1>`
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguestmanutencao/chamadocomuh.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Chamados Com UH</h1>`
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguestmanutencao/manutencaoexterna.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Chamados Manutenção Externa</h1>`
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguestmanutencao/chamadosprogramados.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Chamados Programados</h1>
                <ul>
                    <li> - Chamados Programados</li>
                    <li> - Programação</li>
                    <li> - Checklist</li>
                    <li> - Equipamento</li>
                </ul>`
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguestmanutencao/relatorios.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Relatorios</h1>`
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguestmanutencao/configuracoes.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Configurações da Manutenção</h1>`
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/fdguestmanutencao/downloadapp.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Como baixar o Aplicativo</h1>`
            }
        },
        {
            tipotela:'telacomvideo',
            htmlContentEsquerda:{
                img:'/Treinamentos/pc-apresentacao.jpg',
                css:'width:90%!important;height:90%!important;',
                linkVideo:'https://esvideos.economysoftware.com.br/videos/tutoriais/espot/atendendoumchamado.mp4'
            },
            htmlContentDireita:{
                conteudo:`<h1>Atendendendo um chamado no aplicativo</h1>`
            }
        },
        {
            tipotela:'telacheia',
            htmlContentEsquerda:{
                titulo:`Obrigado!!`,
                subtitulo:``
            },
            htmlContentDireita:{
                conteudo:``
            }
        },
    ]

}